import './vpn-modal.scss'
import {Dispatch, FC, SetStateAction} from "react";
import {Modal} from "../modal/modal";
import {Button, buttonType} from "../button/button";
import {KEY_VPN_MODAL_SEEN} from "../../core/constants/localStorageConstants";

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const VPNModal: FC<Props> = ({isOpen, setIsOpen}) => {
    return (
        <Modal title={"اطلاعیه"} isOpen={isOpen} setIsOpen={setIsOpen} className={"pmx-vpn-modal"}>
            <p>برای تجربه استفاده کامل از خدمات سایت از خاموش بودن VPN مطمئن شوید.</p>
            <Button type={buttonType.primary} text={"متوجه شدم"} onClick={() => {
                setIsOpen(false)
                localStorage.setItem(KEY_VPN_MODAL_SEEN, "true")
            }} />
        </Modal>
    )
}