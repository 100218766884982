import {FC, useEffect, useRef, useState} from "react";
import {ConfigProvider, Tour, TourProps} from "antd";
import "./enter-phone.scss";
import {faNumToEnNum, IsNumeric,} from "../../../../../../../core/utils/decimalExtentions";
import {sendCode} from "../../../../../../../core/repositores/login";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {ReactComponent as InfoCircle} from "assets/icons/general/info-error.svg";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {RootState} from "core/redux/store";
import fa_IR from "antd/lib/locale/fa_IR";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import {clearTokenFromLocalStorage} from "../../../../../../../core/utils/setLocalStorageItems";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const EnterPhone: FC<Props> = ({ state }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
    const [captchaToken, setCaptchaToken] = useState<string>('');
    const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);


    const { executeRecaptcha } = useGoogleReCaptcha();

    const tour1 = useRef(null);
  const tour2 = useRef(null);

  const dispatch = useDispatch();

  const tourSteps: TourProps["steps"] = [
    {
      title: "شماره همراه",
      description: "شماره همراه شامل 11 رقم را وارد نمایید.",
      target: () => tour1.current,
    },
    {
      title: "ورود یا ثبت نام",
      description: "سپس از این بخش اقدام به ورود یا ثبت نام کنید.",
      target: () => tour2.current,
    },
  ];

  const onNumberChange = (value: string) => {
    if (value !== "") value = faNumToEnNum(value);

    if (value !== "" && !IsNumeric(value.split("")[value.split("").length - 1]))
      return;

    setPhoneNumber(value);
  };

  const onBackClick = () =>
    dispatch(
      setOrderData({
        ...state.orderData,
        tour: false,
        stage: OrderCardStages.CALCULATOR,
        phone: null,
      }),
    );

  async function onSendCodeHandle() {
      if (!isRecaptchaReady) {
          setError("کپچا بارگذاری نشده است.");
          return;
      }

    if (phoneNumber.length == 10 && phoneNumber.startsWith("0"))
      return setError("شماره موبایل وارد شده نادرست است.");

    const phoneNum =
      (phoneNumber && phoneNumber?.startsWith("0") ? "" : "0") + phoneNumber;

      const token = await executeRecaptcha('login');
      setCaptchaToken(token);

    const body = {
      phoneNumber: phoneNum,
      forPasswordCheck: true,
        reCaptchaToken: token
    };

    // if (!new RegExp(phoneRegex).test(phoneNumber))
    //   return setError("فرمت شماره همراه وارد شده صحیح نیست!");

    setError(undefined);

    await sendCode(
      setButtonIsLoading,
      body,
      (response) =>
        dispatch(
          setOrderData({
            ...state.orderData,
            stage: response.isRegister
              ? response.hasPassword
                ? OrderCardStages.LOGIN
                : OrderCardStages.TWO_FA
              : OrderCardStages.VERIFY_PHONE,
            phone: phoneNum,
            tour: false,
              forgetPassword: !response.hasPassword
          }),
        ),
      (response) => setError(response.message),
    );
  }

  useEffect(() => {
    dispatch(setOrderData({ ...state.orderData, tour: false }));
  }, []);

  useEffect(() => {
      window.history.pushState(
          window.history.state,
          document.title,
          "/login"
      );
    clearTokenFromLocalStorage();
  }, []);

    useEffect(() => {
        if (executeRecaptcha) {
            setIsRecaptchaReady(true);
        }
    }, [executeRecaptcha]);

  return (
    <div className={"pmx-enter-phone"}>
      <h3>ورود یا ثبت نام</h3>
      <p className={"description"}>
        جهت ورود و یا ثبت نام ابتدا شماره همراهی که به نام خودتان است را وارد
        نمایید.
      </p>
      <div ref={tour1}>
        <AntdInput
          className={`${error?.length ? "error" : ""}`}
          dir={phoneNumber === "" ? "rtl" : "ltr"}
          placeholder={"شماره همراه خود را وارد کنید"}
          suffix={
            <>
              <span className={"vertical-divider"} />
              <div className={"select-country"}>
                <span className={"code"}>+98</span>
                <img src={"/assets/coins/irt.svg"} />
              </div>
            </>
          }
          value={phoneNumber}
          onChange={(e) => onNumberChange(e.target.value)}
          maxLength={11}
          onPressEnter={() => onSendCodeHandle()}
        />
      </div>
      {error?.length && (
        <div className={"error-row"}>
          <InfoCircle />
          <span>{error}</span>
        </div>
      )}

      <div ref={tour2} style={{ marginTop: "auto" }}>
        <OrderCardButtons
          submitButton={{
            text: "ورود یا ثبت نام",
            loading: buttonIsLoading,
            disabled: buttonIsLoading || phoneNumber.length <= 9 || !isRecaptchaReady,
            onClick: () => onSendCodeHandle(),
          }}
          cancelButton={{
            text: "بازگشت",
            onClick: () => onBackClick(),
          }}
        />
      </div>
      <ConfigProvider locale={fa_IR} direction={"rtl"}>
        <Tour
          steps={tourSteps}
          open={state?.orderData?.tour}
          onClose={() =>
            dispatch(setOrderData({ ...state.orderData, tour: false }))
          }
        />
      </ConfigProvider>
    </div>
  );
};

export default connector(EnterPhone);
