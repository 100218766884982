import "./order-card.scss";
import {FC, useEffect} from "react";
import Calculator from "./stages/calculator/calculator";
import EnterPhone from "./stages/enter-phone/enter-phone";
import LoginStage from "./stages/login/login";
import TwoFaStage from "./stages/two-fa/two-fa";
import VerifyPhone from "./stages/verify-phone/verify-phone";
import {OrderCardStages} from "core/domain/landing/landing";
import {RootState} from "../../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import SetPasswordStage from "./stages/set-password/set-password";
import BasicInfoForm from "./stages/basic-info-form/basic-info-form";
import EnterCard from "./stages/enter-card/enter-card";
import EnterWallet from "./stages/enter-wallet/enter-wallet";
import BillPreview from "./stages/bill-preview/bill-preview";
import PaymentResult from "./stages/payment-result/payment-result";
import TrackingOrder from "./stages/tracking-order/tracking-order";
import {setOrderData} from "../../../../../core/redux/actions";
import ReceiveMethod from "./stages/receive-method/receive-method";
import PaymentMethod from "./stages/payment-method/payment-method";
import {useLocation} from "react-router-dom";
import EnterVoucher from "./stages/enter-voucher/enter-voucher";
import EnterWalletDeposit from "./stages/enter-wallet-deposit/enter-wallet-deposit";
import GoogleAuthenticationStage from "./stages/google-authentication/google-authentication";
import {Rate} from "../../../../../core/domain/rate/rate";
import {SITE_KEY} from "../../../../../core/constants/constants";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    rates: Rate[]
    ratesLoading: boolean
}

const OrderCard: FC<Props> = ({state, ratesLoading, rates}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {orderData} = state;
    const URL = window.location;
    const lastSectionUrl = URL.pathname.split("/")[1];
    useEffect(() => {
        if (lastSectionUrl.startsWith("tracking"))
            dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.TRACKING_ORDER,
                }),
            );
    }, []);

    useEffect(() => {
        if (location.pathname?.startsWith("/verify"))
            dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.PAYMENT_RESULT,
                }),
            );
    }, [location]);
    useEffect(() => {
        if (location.pathname.includes("/login"))
            dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.ENTER_PHONE,
                }),
            );
    }, [location]);

    return (
        <div className="pmx-order-card">
            {orderData.stage === OrderCardStages.CALCULATOR && <Calculator rates={rates} ratesLoading={ratesLoading}/>}
            {orderData.stage === OrderCardStages.ENTER_PHONE &&
                <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}><EnterPhone/></GoogleReCaptchaProvider>
            }
            {orderData.stage === OrderCardStages.TWO_FA && <TwoFaStage/>}
            {orderData.stage === OrderCardStages.VERIFY_PHONE && <VerifyPhone/>}
            {orderData.stage === OrderCardStages.LOGIN && <LoginStage/>}
            {orderData.stage === OrderCardStages.SET_PASSWORD && <SetPasswordStage/>}
            {orderData.stage === OrderCardStages.BASIC_INFO_FORM && <BasicInfoForm/>}
            {orderData.stage === OrderCardStages.ENTER_CARD && <EnterCard/>}
            {orderData.stage === OrderCardStages.ENTER_WALLET && <EnterWallet/>}
            {orderData.stage === OrderCardStages.BILL_PREVIEW && <BillPreview/>}
            {orderData.stage === OrderCardStages.TRACKING_ORDER && <TrackingOrder/>}
            {orderData.stage === OrderCardStages.PAYMENT_RESULT && <PaymentResult/>}
            {orderData.stage === OrderCardStages.RECEIVE_METHOD && <ReceiveMethod/>}
            {orderData.stage === OrderCardStages.PAYMENT_METHOD && <PaymentMethod/>}
            {orderData.stage === OrderCardStages.ENTER_WALLET_DEPOSIT && (<EnterWalletDeposit/>)}
            {orderData.stage === OrderCardStages.ENTER_VOUCHER && <EnterVoucher/>}
            {orderData.stage === OrderCardStages.GOOGLE_AUTHENTICATION && <GoogleAuthenticationStage/>}
        </div>
    );
};
export default connector(OrderCard);
