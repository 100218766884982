import * as React from "react";
import {useEffect, useState} from "react";

import "./dashboard-index-page.scss";
import {BalanceBox} from "./balance-box/balance-box";
import {InviteBox} from "./invite-box/invite-box";
import {PriceCard} from "./price-card/price-card";
import {LevelBox} from "./level-box/level-box";
import {DashboardSlider} from "./slider/slider";
import {DashboardRecentTransactions} from "./recent-transactions/recent-transactions";
import {Page} from "../../../../components/page/page";
import {RootState} from "../../../../core/redux/store";
import {connect, ConnectedProps} from "react-redux";
import {Rate} from "../../../../core/domain/rate/rate";
import {getMineOrder} from "../../../../core/repositores/orders";
import {Order, ReferralInfo} from "../../../../core/domain";
import {getMineReferralInfo} from "../../../../core/repositores/referral";
import {PriceCardSkeleton} from "./price-card/price-card-skeleton/price-card-skeleton";
import {getRates} from "../../../../core/repositores/landing";

const mapState = (state: RootState) => ({
  state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const DashboardIndexPage: React.FC<Props> = ({ state }) => {
  const [ratesIsLoading, setRatesIsLoading] = useState(false);
  const [rates, setRates] = useState<Rate[]>([
    {
      name: "تتر",
      symbol: "USDT",
      sellPrice: 0,
    },
    {
      name: 'پرفکت مانی',
      symbol: 'PM',
      sellPrice: 0
    }
  ]);
  const [isReferralInfoLoading, setIsReferralInfoLoading] =
    React.useState(true);
  const [referralInfo, setReferralInfo] = React.useState<ReferralInfo>();
  const [transactionsIsLoading, setTransactionsIsLoading] =
    React.useState(false);
  const [transactions, setTransactions] = React.useState<Order[]>([]);

  const fetchData = async () => {
    await getRates(setRatesIsLoading, setRates);
    await getMineReferralInfo(setIsReferralInfoLoading, setReferralInfo);
    await getMineOrder(setTransactionsIsLoading, setTransactions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className="pmx-dashboard-index-page">
      <div className="head-row">
        <BalanceBox />
        <InviteBox
          referralInfo={referralInfo}
          isLoading={isReferralInfoLoading}
        />
        <div className="prices">
          {ratesIsLoading ? (
            <>
              <PriceCardSkeleton />
              <PriceCardSkeleton />
            </>
          ) : (
            rates
                ?.filter((rate) => rate.symbol !== "CRYPTO" && rate.symbol !== "PM")
              .map((rate) => <PriceCard rate={rate} />)
              .slice(0, 2)
          )}
        </div>
        <LevelBox level={state?.profileData?.level} />
      </div>
      <DashboardSlider />
      <DashboardRecentTransactions
        transactions={transactions}
        isLoading={transactionsIsLoading}
      />
    </Page>
  );
};

export default connector(DashboardIndexPage);
