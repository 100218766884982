import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import "./verify-phone.scss";
import {ToEn} from "../../../../../../../core/utils/decimalExtentions";
import {ReactComponent as Clock} from "assets/icons/home/clock.svg";
import ReactCodeInput from "react-verification-code-input";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {ConfigProvider, Tour, TourProps} from "antd";
import {RootState} from "../../../../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData, setUserData,} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {sendCode, verifyCode,} from "../../../../../../../core/repositores/login";
import {ReactComponent as InfoCircle} from "assets/icons/general/info-error.svg";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import fa_IR from "antd/lib/locale/fa_IR";
import {LoadingOutlined} from "@ant-design/icons";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const VerifyPhone: FC<Props> = ({ state }) => {
  const { orderData } = state;
  const [code, setCode] = useState("");
  const [referral, setReferral] = useState("");
  const [countdown, setCountdown] = useState(120);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [resendIsLoading, setResendIsLoading] = useState(false);

  const tour1 = useRef(null);
  const tour2 = useRef(null);
  const tour3 = useRef(null);
  const tour4 = useRef(null);
  const tour5 = useRef(null);

  const tourSteps: TourProps["steps"] = [
    {
      title: "وارد کردن کد",
      description: "کد 5 رقمی که به شماره همراه شما ارسال شده را وارد نمایید.",
      target: () => tour1.current,
    },
    {
      title: "ارسال مجدد کد",
      description:
        "در صورتی که کدی برای شما ارسال نشد، پس از دو دقیقه میتوانید از این بخش درخواست ارسال مجدد کد نمایید.",
      target: () => tour2.current,
    },
    {
      title: "تغییر شماره همراه",
      description:
        "در صورتی که تصمیم به تغییر شماره همراه جهت ارسال کد دارید، از این بخش اقدام نمایید.",
      target: () => tour3.current,
    },
    {
      title: "کد دعوت",
      description:
        "در صورتی که کد دعوت در اختیار دارید، میتوانید آن را در این بخش وارد کنید.",
      target: () => tour4.current,
    },
    {
      title: "تایید کد",
      description:
        "در صورتی که کد 5 رقمی را وارد کرده اید، از این بخش میتوانید اقدام به تایید کد یا بازگشت نمایید.",
      target: () => tour5.current,
    },
  ];
    useEffect(() => {
        window.history.pushState(
            window.history.state,
            document.title,
            "/enter-2fa"
        );
    }, [])
  const dispatch = useDispatch();
  const onBackClick = () =>
    dispatch(
      setOrderData({
        ...state.orderData,
        stage: OrderCardStages.ENTER_PHONE,
        phone: null,
        tour: false,
      }),
    );

  async function onLoginHandle(isOnClick: boolean, value?: string) {
    const body = {
      code: isOnClick ? code : value,
      referral: referral ?? null,
      phoneNumber: orderData?.phone,
        sourceCampaign: document.referrer
    };
    await verifyCode(
      setButtonIsLoading,
      body,
      (data) => dispatch(setUserData(data)),
      () =>
        dispatch(
          setOrderData({
            ...state.orderData,
            stage: OrderCardStages.SET_PASSWORD,
            tour: false,
          }),
        ),
      () => null,
      (response) => setError(response.message),
    );
  }

  async function onResendCode() {
    const body = {
      phoneNumber: orderData?.phone,
      forPasswordCheck: false,
    };

    setError(undefined);

    await sendCode(setResendIsLoading, body, (response) => setCountdown(120));
  }

  return (
    <div className={"pmx-verify-phone-stage"}>
      <h3>تایید شماره همراه</h3>
      <p className={"description"}>
        کد ۵ رقمی پیامک شده به شماره {orderData?.phone} را در کادر زیر وارد
        نمایید.{" "}
      </p>
      <div className="code">
        <div ref={tour1}>
          <ReactCodeInput
            className={`code-input ${error?.length ? "error" : ""}`}
            fieldWidth={52}
            fieldHeight={52}
            values={code.split("")}
            autoFocus={true}
            onChange={(value) => {
              setCode(ToEn(value));
            }}
            onComplete={(value) => onLoginHandle(false, value)}
            fields={5}
          />
        </div>
        <div className={"options"}>
          {countdown === 0 ? (
            <span
              className={"resend"}
              onClick={() => onResendCode()}
              ref={tour2}
            >
              {resendIsLoading ? <LoadingOutlined /> : "ارسال مجدد"}
            </span>
          ) : (
            <div className={"countdown"} ref={tour2}>
              <Clock />
              <div className="timer">
                <CountdownCircleTimer
                  duration={countdown}
                  isPlaying
                  onComplete={(code) => setCountdown(0)}
                  colors={["#0072FE", "#ff4545"]}
                  colorsTime={[120, 0.000001]}
                  strokeWidth={0}
                  trailStrokeWidth={0}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </div>
              <span>ثانیه دیگر تا ارسال مجدد</span>
            </div>
          )}
          <span
            className={"change-number"}
            onClick={() => onBackClick()}
            ref={tour3}
          >
            تغییر شماره همراه
          </span>
        </div>
        {error?.length && (
          <div className={"error-row"}>
            <InfoCircle />
            <span>{error}</span>
          </div>
        )}
        <div ref={tour4}>
          <AntdInput
            placeholder={"در صورت داشتن کد دعوت، کد را اینجا وارد کنید..."}
            value={referral}
            onChange={(e) => setReferral(e.target.value)}
          />
        </div>
      </div>

      <div ref={tour5}>
        <OrderCardButtons
          submitButton={{
            text: "تایید کد",
            disabled: code.split("").length !== 5 || buttonIsLoading,
            loading: buttonIsLoading,
            onClick: () => onLoginHandle(true),
          }}
          cancelButton={{
            text: "بازگشت",
            onClick: () => onBackClick(),
          }}
        />
      </div>

      <ConfigProvider locale={fa_IR} direction={"rtl"}>
        <Tour
          open={state?.orderData?.tour}
          onClose={() =>
            dispatch(setOrderData({ ...state.orderData, tour: false }))
          }
          steps={tourSteps}
        />
      </ConfigProvider>
    </div>
  );
};
export default connector(VerifyPhone);
