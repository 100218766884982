import "./bill-preview.scss";
import swap from "assets/images/home/swap.png";
import {ReactComponent as InfoIcon} from "assets/icons/home/info.svg";
import {ReactComponent as ExpandIcon} from "assets/icons/home/expand.svg";
import {ReactComponent as WarningIcon} from "assets/icons/home/warning-collapse.svg";
import {Alert, Collapse, ConfigProvider, Tour, TourProps} from "antd";
import {RootState} from "../../../../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {digitSeparator} from "../../../../../../../core/utils/decimalExtentions";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {setOrderData} from "../../../../../../../core/redux/actions";
import {OrderCardStages, Side,} from "../../../../../../../core/domain/landing/landing";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import {useEffect, useRef, useState} from "react";
import {ReactComponent as CopyIcon} from "assets/icons/home/copy.svg";
import {copyToClipboard} from "core/utils/copyToClipboard";
import {postViaAuth, responseValidator,} from "../../../../../../../core/apis/api";
import {__API} from "../../../../../../../core/apis/api-routes";
import fa_IR from "antd/lib/locale/fa_IR";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

const BillPreview = ({ state }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    paymentLink,
    paymentMethod,
    side,
    destinationWallet,
    sourceMarket,
    sourceAmount,
    network,
    destinationMarket,
    moneyAccount,
  } = state?.orderData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tour1 = useRef(null)
  const tour2 = useRef(null)
  const tour3 = useRef(null)
  const tour4 = useRef(null)
  const tour5 = useRef(null)
  const tour6 = useRef(null)
  const tour7 = useRef(null)
  const tour8 = useRef(null)
  const tour9 = useRef(null)
  const tour10 = useRef(null)
  const tour11 = useRef(null)
  const tour12 = useRef(null)
  const tour13 = useRef(null)
  const tour14 = useRef(null)
  const tour15 = useRef(null)
  const tour16 = useRef(null)

  const tourSteps: TourProps["steps"] = [
    {
      title: "ارز مبدا",
      description: "ارزی که در طرف اول معامله و جهت خرید ارز مقصد استفاده می‌شود.",
      target: () => tour1?.current,
    },
    {
      title: "ارز مقصد",
      description: "ارزی که به آن می‌خواهید در معامله دست یابید یا دریافت کنید.",
      target: () => tour2?.current,
    },
    {
      title: "مقدار پرداختی",
      description: "مقداری که باید برای خرید ارز مقصد پرداخت کنید.",
      target: () => tour3?.current,
    },
    {
      title: "مقدار دریافتی",
      description: "مقداری که به عنوان نتیجه معامله دریافت می‌شود.",
      target: () => tour4?.current,
    },
    {
      title: "آدرس دریافت",
      description: "آدرس کیف پول برای دریافت ارز.",
      target: () => tour5?.current,
    },
    {
      title: "شبکه ی دریافت",
      description: "شبکه‌ای که برای انجام معامله و دریافت ارز انتخاب کرده‌اید.",
      target: () => tour6?.current,
    },
    {
      title: "آدرس مقصد",
      description: "آدرس کیف پول ارسال کننده برای دریافت پرفکت مانی.",
      target: () => tour7?.current,
    },
    {
      title: "روش پرداخت",
      description: "روشی که برای انجام معامله و پرداخت مبلغ ارزی انتخاب کرده‌اید.",
      target: () => tour8?.current,
    },
    {
      title: "کد پیگیری سفارش",
      description: "کدی که به منظور پیگیری وضعیت معامله و سفارش اختصاص یافته است.",
      target: () => tour9?.current,
    },
    {
      title: "کارمزد تراکنش",
      description: "مبلغی که باید بابت ثبت تراکنش پرداخت شود.",
      target: () => tour10?.current,
    },
    {
      title: "شماره شبا",
      description: "شماره شبای مربوط به حساب پرداخت.",
      target: () => tour11?.current,
    },
    {
      title: "نام بانک",
      description: "نام بانک مربوطه.",
      target: () => tour12?.current,
    },
    {
      title: "شناسه واریز",
      description: "شناسه اختصاصی برای انجام واریز.",
      target: () => tour13?.current,
    },
    {
      title: "شماره حساب",
      description: "شماره حساب مقصد برای انتقال وجه.",
      target: () => tour14?.current,
    },
    {
      title: "به نام",
      description: "نام صاحب حساب مقصد.",
      target: () => tour15?.current,
    },
    {
      title: "نکات ضروری حین پرداخت",
      description: "مواردی که باید در هنگام انجام پرداخت توجه داشته باشید.",
      target: () => tour16?.current,
    },
  ];
  useEffect(() => {

    window.history.pushState(
        window.history.state,
        document.title,
        "/factor"
    );
  }, [])
  const collapseItems = [
    {
      key: "0",
      label: (
        <>
          <WarningIcon /> نکات ضروری در پرداخت{" "}
          {state?.orderData?.paymentMethod === 1
            ? "درگاه پرداخت"
            : "واریز به حساب"}
        </>
      ),
      children: (
        <p>
          {state.orderData.paymentMethod === 2 ? (
            <>
              ۱- فقط از طریق کارت ثبت شده اقدام به واریز نمایید در غیر این صورت مبلغ
              پرداختی ضبط و بعد از ۷۲ ساعت کاری عودت داده می شود.
              <br />
              ۲- لطفا مبلغ را به صورت کامل به شماره کارت اعلام شده واریز نمایید.
              <br />
              ۳- حتما شناسه واریز را در پرداخت وارد نمایید در غیر اینصورت واریزی شما
              تایید نخواهد شد.
              <br />
              ۴- پس از واریز وجه و بررسی صحت اطلاعات توسط ادمین به شما اطلاع داده
              خواهد شد.
            </>
          ) :
            <>
              ۱- چنانچه پرداختی شما ناموفق باشد باید ابتدا 72 ساعت منتظر بمانید تا پول به حساب شما عودت بخورد در غیر این صورت پرینت حساب مهر دار برای پشتیبان های ما ارسال نمائید.
              <br />
              ۲- در هنگام پرداخت حتما فیلتر شکن خود را خاموش نمائید.
              <br />
              ۳- سقف خرید از طریق درگاه 25 میلیون تومان در روز می باشد.
              <br />
              ۴- فقط میتوانید با کارت بانکی که ثبت کرده ایید اقدام به پرداخت نمائید.
            </>
          }
        </p>
      ),
    },
  ];


  const onPaidClick = () => {
    toast.success(
      "بعد از رسیدن وجه نتیجه از طریق پیامک به شما اعلام خواهد شد لطفا منتظر باشید.",
    );
    dispatch(
      setOrderData({
        ...state.orderData,
        stage: OrderCardStages.CALCULATOR,
        tour: false,
        cashInDetail: null,
      }),
    );
  };

  const onPurchaseHandle = () => {
    if (side === Side.Swap) {
      createTradeOrder();
      return;
    } else if (side === Side.Buy) {
      if (paymentMethod === 1 && paymentLink) {
        setLoading(true);
        dispatch(setOrderData({...state.orderData, cashInDetail: null}))
        window.open(state?.orderData?.paymentLink, "_self");
        toast.success("در حال انتقال به درگاه پرداخت...");
      } else {
        onPaidClick();
      }
    }
  };

  const createTradeOrder = async () => {
    setLoading(true);
    const body = {
      sourceAmount: sourceAmount,
      sourceMarket: sourceMarket.symbol,
      destinationMarket: destinationMarket.symbol,
      destinationNetwork: network,
      destinationWallet: destinationWallet,
      moneyAccount: destinationMarket.symbol === "PM" ? moneyAccount : null,
    };

    await postViaAuth(__API.orders.createTrade, body).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        sourceMarket?.symbol === "PM"
          ? dispatch(
            setOrderData({
              ...state.orderData,
              stage: OrderCardStages.ENTER_VOUCHER,
              trackingCode: response.data.trackingCode,
              fee: response.data.fee,
              cashInDetail: null,
              tour: false
            }),
          )
          : dispatch(
            setOrderData({
              ...state.orderData,
              stage: OrderCardStages.ENTER_WALLET_DEPOSIT,
              trackingCode: response.data.trackingCode,
              fee: response.data.fee,
              cashInDetail: null,
              tour: false
            }),
          );
      }
    });
  };

  const onBackClick = () =>
    side === Side.Swap
      ? dispatch(
        setOrderData({
          ...state.orderData,
          stage: OrderCardStages.ENTER_WALLET,
          phone: null,
          cashInDetail: null,
          tour: false,
        }),
      )
      : dispatch(
        setOrderData({
          ...state.orderData,
          stage: OrderCardStages.PAYMENT_METHOD,
          phone: null,
          cashInDetail: null,
          tour: false,
        }),
      );

  return (
    <div className="pmx-bill-preview-stage">
      <h3>نگاه کلی</h3>
      <p>اطلاعات خود را بررسی نموده و سپس سفارش خود را نهایی نمایید.</p>
      <div className="bill-preview-content">
        <div className="images">
          <img
            src={`assets/coins/${destinationMarket?.symbol?.toLowerCase()}.svg`}
            className="coin"
            alt="icon"
          />
          <img src={swap} className="swap" alt="icon" />
          <img
            src={`assets/coins/${sourceMarket?.symbol?.toLowerCase()}.svg`}
            className="coin"
            alt="icon"
          />
        </div>
        <ul className="bill-info">
          {side === Side.Swap ? (
            <>
              <li className="bill-row" ref={tour1}>
                <span>ارز مبدا</span>
                <span>{sourceMarket?.name}</span>
              </li>
              <li className="bill-row" ref={tour2}>
                <span>ارز مقصد</span>
                <span>{destinationMarket?.name}</span>
              </li>
            </>
          ) : (
            <li className="bill-row">
              <span>ارز </span>
              <span>
                {destinationMarket?.symbol === "IRT"
                  ? sourceMarket?.name
                  : destinationMarket?.name}
              </span>
            </li>
          )}
          <li className="bill-row" ref={tour3}>
            <span>مقدار پرداختی</span>
            <span>
              {sourceMarket?.symbol === "IRT"
                ? `${digitSeparator(state?.orderData?.sourceAmount)} تومان`
                : `${state?.orderData?.sourceAmount} واحد`}
            </span>
          </li>
          <li className="bill-row" ref={tour4}>
            <span>مقدار دریافتی</span>
            <span>
              {destinationMarket?.symbol === "IRT"
                ? `${digitSeparator(state?.orderData?.destinationAmount)} تومان`
                : `${state?.orderData?.destinationAmount} واحد`}
            </span>
          </li>
          {state?.orderData?.destinationWallet ? (
            <li className="bill-row">
              <span>آدرس دریافت</span>
              <span className="en copiable long-text">
                <span className={"en"}>{state?.orderData?.destinationWallet}</span>
                <CopyIcon
                  onClick={() =>
                    copyToClipboard(
                      state?.orderData?.destinationWallet,
                      "آدرس دریافت",
                    )
                  }
                />
              </span>
            </li>
          ) : null}

          {network && (
            <li className="bill-row" ref={tour6}>
              <span>شبکه دریافت</span>
              <span className="en">{network.toUpperCase()}</span>
            </li>
          )}
          {destinationMarket?.symbol === "PM" &&
            state?.orderData?.moneyAccount ? (
            <li className="bill-row" ref={tour7}>
              <span>آدرس مقصد</span>
              <span className="en copiable">
                <span>{state?.orderData?.moneyAccount}</span>
                <CopyIcon
                  onClick={() =>
                    copyToClipboard(state?.orderData?.moneyAccount, "آدرس مقصد")
                  }
                />
              </span>
            </li>
          ) : null}
          {side === Side.Buy && (
            <li className="bill-row" ref={tour8}>
              <span>روش پرداخت</span>
              <span>
                {state?.orderData?.paymentMethod === 1
                  ? "درگاه پرداخت"
                  : "واریز به حساب"}
              </span>
            </li>
          )}
          {state?.orderData.trackingCode ? (
            <li className="bill-row" ref={tour9}>
              <span>کد پیگیری سفارش</span>
              <span className="en copiable">
                <span className="en">{state?.orderData?.trackingCode}</span>
                <CopyIcon
                  onClick={() =>
                    copyToClipboard(
                      state?.orderData?.trackingCode,
                      "کد پیگیری سفارش",
                    )
                  }
                />
              </span>
            </li>
          ) : null}
          {state?.orderData?.fee ? (
            <li className="bill-row" ref={tour10}>
              <span>کارمزد تراکنش</span>
              <span>{digitSeparator(state?.orderData?.fee)} تومان</span>
            </li>
          ) : null}
          {state?.orderData?.cashInDetail ? (
            <>
              <li className={"bill-row"} ref={tour11}>
                <span>شماره شبا</span >
                <span className="en copiable">
                  <span>{state?.orderData?.cashInDetail?.iban}</span>
                  <CopyIcon
                    onClick={() =>
                      copyToClipboard(
                        state?.orderData?.trackingCode,
                        "شماره شبا",
                      )
                    }
                  />
                </span>
              </li>
              <li className={"bill-row"} ref={tour12}>
                <span>نام بانک</span>
                <span>{state?.orderData?.cashInDetail?.bankName}</span>
              </li>
              <li className={"bill-row"} ref={tour13}>
                <span>شناسه واریز</span>
                <span className="en copiable">
                  <span>{state?.orderData?.cashInDetail?.depositId}</span>
                  <CopyIcon
                    onClick={() =>
                      copyToClipboard(
                        state?.orderData?.trackingCode,
                        "شناسه واریز",
                      )
                    }
                  />
                </span>
              </li>
              <li className={"bill-row"} ref={tour14}>
                <span>شماره حساب</span>
                <span className="en copiable">
                  <span>{state?.orderData?.cashInDetail?.accountNumber}</span>
                  <CopyIcon
                    onClick={() =>
                      copyToClipboard(
                        state?.orderData?.trackingCode,
                        "شماره حساب",
                      )
                    }
                  />
                </span>
              </li>
              <li className={"bill-row"} ref={tour15}>
                <span>به نام</span>
                <span>{state?.orderData?.cashInDetail?.owner}</span>
              </li>
            </>
          ) : null}
        </ul>
      </div>
      {side === Side.Buy && (
          <Alert
              icon={<WarningIcon/>} type={'error'} showIcon={true}
              message={'لطفا قبل انتقال له درگاه از خاموش بودن یا قطع بودن فیلتر شکن (VPN) خود اطمینان حاصل کنید.'}/>
      )}
      {side === Side.Buy && (
        <Collapse
        ref={tour16}
          items={collapseItems}
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <div className="preview-button">
              <ExpandIcon
                style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
              />
              {isActive ? "بستن" : "مشاهده"}
            </div>
          )}
        />
      )}
      {side === Side.Buy && (
        <p className="rules">
          <InfoIcon />
          ادامه ی پرداخت به منزله ی موافقت با{" "}
          <a target="_blank" href="terms"> &nbsp;قوانین پی ام اکسچنج&nbsp;</a> میباشد.
        </p>
      )}
      <OrderCardButtons
        submitButton={{
            text:
                side === Side.Swap
                    ? "پرداخت فاکتور"
                    : state?.orderData?.paymentMethod === 2
                        ? "پرداخت شد"
                        : "پرداخت فاکتور",

            onClick: onPurchaseHandle,
          loading: loading,
          disabled: loading,
        }}
        cancelButton={{
          text: "بازگشت",
          onClick: onBackClick,
        }}
      />

      <ConfigProvider locale={fa_IR} direction={"rtl"}>
        <Tour
          open={state?.orderData?.tour}
          onClose={() =>
            dispatch(setOrderData({ ...state.orderData, tour: false }))
          }
          steps={tourSteps}
        />
      </ConfigProvider>
    </div>
  );
};
export default connector(BillPreview);
