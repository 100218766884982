import {BrowserRouter} from "react-router-dom";
import "./App.css";
import "core/styles/globals.scss";
import {Pmx} from "./routes/pmx";
import {Provider} from "react-redux";
import {store} from "core/redux/store";
import {Toaster} from "react-hot-toast";
import {SITE_KEY} from "./core/constants/constants";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

function App() {
    return (
        <Provider store={store}>
                <Toaster position={"top-right"}/>
                <BrowserRouter>
                    <Pmx/>
                </BrowserRouter>
        </Provider>
    );
}

export default App;
