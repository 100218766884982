import "./home.scss";
import Rates from "./components/rates/rates";
import HomeLanding from "./components/landing/landing";
import {Help} from "./components/help/help";
import {DownloadApplication} from "./components/download-application/download-application";
import {SpotRates} from "./components/spot-rates/spot-rates";
import {TelegramBot} from "./components/telegram-bot/telegram-bot";
import {Services} from "./components/services/services";
import {Articles} from "./components/articles/articles";
import {Footer} from "./components/footer/footer";
import {useEffect, useState} from "react";
import {Rate} from "../../../core/domain/rate/rate";
import {setOrderData} from "../../../core/redux/actions";
import {getRates, getTRXRate} from "../../../core/repositores/landing";
import MostsOfLast24 from "./components/mosts-of-last-24/mosts-of-last-24";
import * as React from "react";
import {KEY_VPN_MODAL_SEEN} from "../../../core/constants/localStorageConstants";
import {VPNModal} from "../../../components/vpn-modal/vpn-modal";

export const Home = () => {
    const [ratesIsLoading, setRatesIsLoading] = useState(false);
    const [rates, setRates] = useState<Rate[]>([]);
    const [vpnModalIsOpen, setVpnModalIsOpen] = React.useState(false);

    async function fetchData() {
        const onComplete = () => {
        }

        await getRates(setRatesIsLoading, setRates, onComplete);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        localStorage.getItem(KEY_VPN_MODAL_SEEN) === "true" ? setVpnModalIsOpen(false) : setVpnModalIsOpen(true);
    }, []);

    return (
        <div className="pmx-home">
            <VPNModal isOpen={vpnModalIsOpen} setIsOpen={setVpnModalIsOpen}/>
            <HomeLanding rates={rates} ratesLoading={ratesIsLoading}/>
            <MostsOfLast24 rates={rates} ratesLoading={ratesIsLoading}/>
            <Rates rates={rates} ratesLoading={ratesIsLoading}/>
            <Help/>
            <DownloadApplication/>
            <SpotRates/>
            <TelegramBot/>
            <Services/>
            <Articles/>
            <Footer/>
        </div>
    );
};
